export const bankAccountTypeOptions = [
    {
        value: "1",
        label: "Savings",
    },
    {
        value: "2",
        label: "Checking",
    }
]

export const emptyBankContext = {
    accountNumber: '',
    accountOwnerName: '',
    accountType: '',
    bankName: '',
    routingNumber: '',
    taxPayer: '',
}